<template>
  <div>
    <H5header
      :date="date"
      v-if="date"
      @getwara="getwara"
      :title="name"
    ></H5header>

    <div v-if="name == '单位简介' && template == 'channel'" class="brief">
      <img src="../h5/image/brief.png" alt="" />

      <div class="frame-box" v-if="datedan">
        <img src="../h5/image/briefsj.png" alt="" />
        <div class="frame" v-html="datedan.content"></div>
      </div>
    </div>

    <div v-if="name == '培训课堂'">
      <div class="nav">
        <div @click="getnav(1)" :class="navIndex == 1 ? 'navselect' : ''">
          裁判员培训
        </div>
        <div @click="getnav(2)" :class="navIndex == 2 ? 'navselect' : ''">
          赛事培训
        </div>
      </div>
      <div class="xians"></div>

      <div v-if="navIndex == 1">
        <competition id="803" pid="739"></competition>
      </div>

      <div v-if="navIndex == 2">
        <div
          class="articlelist"
          v-for="(item, index) in list"
          :key="index"
          @click="jump(item)"
        >
          <div class="title">{{ item.title }}</div>
          <div class="date">{{ item.publishTime | format }}</div>
          <div class="xian"></div>
        </div>
        <div class="paging">
          <pagination
            :total="total"
            @currentchange="currentchange"
            :type="2"
            :go="10"
            :pageSize="10"
            :refreshNum="refreshNum"
            :pageNo="pageNum"
          ></pagination>
        </div>
      </div>
    </div>

    <div v-else-if="template != 'channel'">
      <div
        class="articlelist"
        v-for="(item, index) in list"
        :key="index"
        v-if="
          template == 'picture_column' ||
          template == 'picture_list' ||
          template == 'picture'
        "
        @click="jump(item)"
      >
        <div class="imgbox">
          <div class="title-img">{{ item.title }}</div>
          <img :src="item.imgUrl" alt="" v-if="item.imgUrl" />
          <img src="../../assets/image/placeholder.png" alt="" v-else />
        </div>
        <div class="date">{{ item.publishTime | format }}</div>
        <div class="xian"></div>
      </div>

      <div
        v-for="(item, index) in list"
        :key="index"
        v-if="template == 'date_list'"
        @click="jump(item)"
      >
        <div style="height: 72PX; padding: 20PX">
          <div class="news-date">
            <b>{{ item.publishTime.split(' ')[0].split('-')[2] }}</b>
            <p>
              {{
                item.publishTime.split(' ')[0].split('-')[0]
              }}-{{ item.publishTime.split("
							")[0].split(" - ")[1]}}
            </p>
          </div>
          <h2>
            {{ item.title }}
          </h2>
        </div>
        <div class="xian" style="margin: 30PX 0 0 0"></div>
      </div>

      <div
        class="articlelist"
        v-for="(item, index) in list"
        :key="index"
        v-if="template == 'common_list'"
        @click="jump(item)"
      >
        <div class="title">{{ item.title }}</div>
        <div class="date">{{ item.publishTime | format }}</div>
        <div class="xian"></div>
      </div>

      <div
        class="articlelist"
        v-for="(item, index) in list"
        :key="index"
        v-if="template == ''"
        @click="jump(item)"
      >
        <div class="title">{{ item.title }}</div>
        <div class="date">{{ item.publishTime | format }}</div>
        <div class="xian"></div>
      </div>
    </div>
    <div v-else-if="name != '单位简介' && name != '培训课堂'" style="margin: 15PX">
      <div v-html="datedan.content || ''"></div>
      <div
        v-if="
          datedan.contentFileUrl &&
          JSON.parse(datedan.contentFileUrl).length > 0
        "
      >
        <ul>
          <li
            v-for="(item, index) in JSON.parse(datedan.contentFileUrl)"
            :key="index"
            style="margin-bottom: 10PX"
          >
            <a :href="item.url" style="color: #2d52a0">
              <img src="/images/fujian.png" alt="" style="width: 20PX" />
              {{ item.name }}
            </a>
          </li>
        </ul>
      </div>
    </div>

    <div v-if="name == '登记/查询'" class="brief">
      <eventquery :id="id" :pid="pid"></eventquery>
    </div>

    <div v-if="name == '最高纪录'" class="brief">
      <record :id="id" :pid="pid"></record>
    </div>

    <div v-if="name == '成绩查询'" class="brief">
      <game :id="id" :pid="pid"></game>
    </div>

    <div v-if="name == '赛事报名'" class="brief">
      <eventregistration :id="id" :pid="pid"></eventregistration>
    </div>

    <div v-if="name == '竞赛知识' || name == '裁判员培训'" class="brief">
      <competition :id="id" :pid="pid"></competition>
    </div>

    <div v-if="name == '注册/查询'" class="brief">
      <registrationquery :id="id" :pid="pid"></registrationquery>
    </div>

    <div v-if="template != 'channel'">
      <div
        class="paging"
        v-if="
          name != '单位简介' &&
          name != '登记/查询' &&
          name != '最高纪录' &&
          name != '成绩查询' &&
          name != '赛事报名' &&
          name != '竞赛知识' &&
          name != '注册/查询' &&
          name != '裁判员培训' &&
          name != '培训课堂'
        "
      >
        <pagination
          :total="total"
          @currentchange="currentchange"
          :type="2"
          :go="10"
          :pageSize="10"
          :refreshNum="refreshNum"
          :pageNo="pageNum"
        ></pagination>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import Meta from 'vue-meta'
Vue.use(Meta)
import H5header from "./components/header2.vue"
import eventquery from "./event-query.vue"
import record from "./record.vue"
import game from "./game.vue"
import eventregistration from "./event-registration.vue"
import competition from "./competition.vue"
import registrationquery from "./registration-query.vue"
export default {
  name: '',
  components: {
    H5header,
    eventquery,
    record,
    game,
    eventregistration,
    competition,
    registrationquery
  },
  data() {
    return {
      date: {},
      pageNum: 1,
      pageSize: 10,
      refreshNum: 1,
      total: 0,
      list: [],
      template: 'common_list',
      name: '',
      id: '',
      pid: '',
      datedan: {},
      navIndex: 1
    }
  },
  filters: {
    format(x) {
      if (x != null && x != '') {
        return x.split(" ")[0]
      }
    }
  },
  created() {
    this.id = this.$route.query.id
    this.pid = this.$route.query.pid
    this.name = this.$route.query.name
    this.findAllColumn()

    document.title = this.$route.query.name + '-北京市体育竞赛管理和国际交流中心'
    this.$meta().refresh()

    this.$getwxsdk(this.$route.query.name + '-北京市体育竞赛管理和国际交流中心')
  },
  methods: {
    currentchange(e) {
      this.pageNum = e
      const params = new URLSearchParams(window.location.search)
      const param1Value = params.get('id');
      this.getArticleListById(param1Value)
    },
    findAllColumn() {
      let data = {
        id: this.$route.query.id,
        pid: this.$route.query.pid
      }
      this.$api.findAllColumn(data).then(res => {
        this.date = res.data.data
        this.template = res.data.data.pid.template

        document.title = res.data.data.pid.name + '-北京市体育竞赛管理和国际交流中心'
        this.$meta().refresh()

        if (this.template == 'channel') {
          this.getArticleById(this.$route.query.id)
        } else {
          this.getArticleListById(this.$route.query.id)
        }
      })
    },
    getwara(e) {
      this.pageNum = 1
      this.refreshNum = 1 + e.id
      this.navIndex = 1
      this.list = []

      if (e.template == 'channel') {
        this.getArticleById(e.id)
      } else {
        this.getArticleListById(e.id)
      }
      this.template = e.template
      this.name = e.name
      this.id = e.id
      this.pid = e.parentId

      document.title = e.name + '-北京市体育竞赛管理和国际交流中心'
      this.$meta().refresh()

      const urlParams = new URLSearchParams(window.location.search);
      urlParams.set('id', e.id); // 设置参数名和值
      urlParams.set('name', e.name); // 设置参数名和值
      urlParams.set('pid', e.parentId); // 设置参数名和值
      urlParams.set('refresh', new Date().getTime())
      const newURL = `${window.location.origin}${window.location.pathname}?${urlParams.toString()}`;
      window.history.replaceState(null, '', newURL); // 更新浏览器地址栏
    },
    getArticleListById(e) {
      this.list = []
      let data = {
        id: e,
        pageNum: this.pageNum,
        pageSize: this.pageSize,
      }
      this.$api.getArticleListById(data).then(res => {
        this.total = res.data.data.total
        this.list = res.data.data.list
        this.list.forEach(item => {
          item.title = item.title.replace(/<br>/g, ' ')
        })
      })
    },
    jump(item) {
      if (this.$route.query.id == 728 && this.$route.query.pid == 847) {
        if (this.$store.state.token == '') {
          this.$confirm('请登录后查看更多信息', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            this.$store.commit("EXIT_TOKEN")
            this.$router.push({
              path: '/h5/login',
              query: {
                person: true,
                grp: false,
                refresh: new Date().getTime()
              }
            })
          }).catch(() => { });
          return
        }
      }
      this.$router.push({
        path: '/h5/H5articledetails',
        query: {
          id: item.id,
          refresh: new Date().getTime(),
        }
      })
    },
    getArticleById(e) {
      this.$api.getArticleById({ id: e }).then(res => {
        this.datedan = res.data.data
      })
    },
    getnav(e) {
      this.navIndex = e
      if (e == 2) {
        this.getArticleListById(829)
      }
    },
  }
}
</script>

<style scoped lang="scss">
.articlelist {
  .title {
    height: 36PX;
    font-size: 14PX;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #333333;
    margin: 17PX 22PX 0 22PX;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  .date {
    font-size: 11PX;
    font-family: Source Han Sans CN;
    font-weight: 400;
    margin-top: 5PX;
    margin-right: 23PX;
    text-align: right;
    margin-bottom: 12PX;
  }

  .imgbox {
    margin: 17PX 22PX 0 22PX;
    display: flex;
    justify-content: space-between;

    img {
      width: 114PX;
      height: 70PX;
      border-radius: 10PX;
      flex-shrink: 0;
    }

    .title-img {
      height: 73PX;
      font-size: 14PX;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #333333;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 4;
      line-clamp: 4;
      -webkit-box-orient: vertical;
      margin-right: 10PX;
    }
  }
}

.news-date {
  width: 72PX;
  height: 72PX;
  border: 1PX solid #bfd4e9;
  float: left;
  margin-right: 20PX;
  font-family: arial;
  color: #6198e2;
  background: #fff;
  text-align: center;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1) 0s;
}

.news-date b {
  height: 43PX;
  line-height: 42PX;
  font-size: 26PX;
}

.news-date p {
  font-size: 14PX;
  margin: 0 5PX;
  height: 26PX;
  line-height: 26PX;
  color: #6198e2;
  background: #fff;
  border-top: 1PX solid #dfe7f3;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1) 0s;
}

h2 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  line-clamp: 4;
  -webkit-box-orient: vertical;
}

.xian {
  width: 100%;
  height: 4PX;
  background: #f4f8fb;
}

.brief {
  img {
    width: calc(100% - 40PX);
    height: 100%;
    margin: 20PX;
  }
}

.frame-box {
  width: calc(100% - 40PX);
  margin: 20PX;

  img {
    width: 22PX;
    height: 16PX;
    margin: 0 0 0 40PX;
  }

  .frame {
    width: 100%;
    min-height: 500PX;
    border: 1PX solid rgba(50, 131, 243, 0.2);
    border-radius: 25PX;
    background: #f3f9ff;
    padding: 18PX;
  }
}

.paging {
  margin: 0 22PX 22PX;
  display: flex;
  align-items: center;
}

.paging span {
  margin-right: 5PX;
}

.nav {
  display: flex;
  justify-content: center;
  margin-top: 27PX;

  div {
    width: 42%;
    height: 35PX;
    background: #e8f2ff;
    border-radius: 20PX 20PX 0PX 0PX;
    font-family: SourceHanSansCN;
    font-weight: bold;
    font-size: 15PX;
    color: #437dca;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 16PX;
    cursor: pointer;
  }
}

.xians {
  width: 100%;
  height: 2PX;
  background: #85b0e9;
  border-radius: 1PX;
  margin-bottom: 10PX;
}

.navselect {
  background: linear-gradient(90deg, #6fb2ff, #095fff) !important;
  box-shadow: 0PX 7PX 16PX 0PX rgba(50, 129, 255, 0.51);
  border-radius: 20PX 20PX 0PX 0PX;
  color: #ffffff !important;
}
</style>