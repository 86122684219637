var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.date)?_c('H5header',{attrs:{"date":_vm.date,"title":_vm.name},on:{"getwara":_vm.getwara}}):_vm._e(),(_vm.name == '单位简介' && _vm.template == 'channel')?_c('div',{staticClass:"brief"},[_c('img',{attrs:{"src":require("../h5/image/brief.png"),"alt":""}}),(_vm.datedan)?_c('div',{staticClass:"frame-box"},[_c('img',{attrs:{"src":require("../h5/image/briefsj.png"),"alt":""}}),_c('div',{staticClass:"frame",domProps:{"innerHTML":_vm._s(_vm.datedan.content)}})]):_vm._e()]):_vm._e(),(_vm.name == '培训课堂')?_c('div',[_c('div',{staticClass:"nav"},[_c('div',{class:_vm.navIndex == 1 ? 'navselect' : '',on:{"click":function($event){return _vm.getnav(1)}}},[_vm._v(" 裁判员培训 ")]),_c('div',{class:_vm.navIndex == 2 ? 'navselect' : '',on:{"click":function($event){return _vm.getnav(2)}}},[_vm._v(" 赛事培训 ")])]),_c('div',{staticClass:"xians"}),(_vm.navIndex == 1)?_c('div',[_c('competition',{attrs:{"id":"803","pid":"739"}})],1):_vm._e(),(_vm.navIndex == 2)?_c('div',[_vm._l((_vm.list),function(item,index){return _c('div',{key:index,staticClass:"articlelist",on:{"click":function($event){return _vm.jump(item)}}},[_c('div',{staticClass:"title"},[_vm._v(_vm._s(item.title))]),_c('div',{staticClass:"date"},[_vm._v(_vm._s(_vm._f("format")(item.publishTime)))]),_c('div',{staticClass:"xian"})])}),_c('div',{staticClass:"paging"},[_c('pagination',{attrs:{"total":_vm.total,"type":2,"go":10,"pageSize":10,"refreshNum":_vm.refreshNum,"pageNo":_vm.pageNum},on:{"currentchange":_vm.currentchange}})],1)],2):_vm._e()]):(_vm.template != 'channel')?_c('div',[_vm._l((_vm.list),function(item,index){return (
          _vm.template == 'picture_column' ||
          _vm.template == 'picture_list' ||
          _vm.template == 'picture'
        )?_c('div',{key:index,staticClass:"articlelist",on:{"click":function($event){return _vm.jump(item)}}},[_c('div',{staticClass:"imgbox"},[_c('div',{staticClass:"title-img"},[_vm._v(_vm._s(item.title))]),(item.imgUrl)?_c('img',{attrs:{"src":item.imgUrl,"alt":""}}):_c('img',{attrs:{"src":require("../../assets/image/placeholder.png"),"alt":""}})]),_c('div',{staticClass:"date"},[_vm._v(_vm._s(_vm._f("format")(item.publishTime)))]),_c('div',{staticClass:"xian"})]):_vm._e()}),_vm._l((_vm.list),function(item,index){return (_vm.template == 'date_list')?_c('div',{key:index,on:{"click":function($event){return _vm.jump(item)}}},[_c('div',{staticStyle:{"height":"72PX","padding":"20PX"}},[_c('div',{staticClass:"news-date"},[_c('b',[_vm._v(_vm._s(item.publishTime.split(' ')[0].split('-')[2]))]),_c('p',[_vm._v(" "+_vm._s(item.publishTime.split(' ')[0].split('-')[0])+"-"+_vm._s(item.publishTime.split(" ")[0].split(" - ")[1])+" ")])]),_c('h2',[_vm._v(" "+_vm._s(item.title)+" ")])]),_c('div',{staticClass:"xian",staticStyle:{"margin":"30PX 0 0 0"}})]):_vm._e()}),_vm._l((_vm.list),function(item,index){return (_vm.template == 'common_list')?_c('div',{key:index,staticClass:"articlelist",on:{"click":function($event){return _vm.jump(item)}}},[_c('div',{staticClass:"title"},[_vm._v(_vm._s(item.title))]),_c('div',{staticClass:"date"},[_vm._v(_vm._s(_vm._f("format")(item.publishTime)))]),_c('div',{staticClass:"xian"})]):_vm._e()}),_vm._l((_vm.list),function(item,index){return (_vm.template == '')?_c('div',{key:index,staticClass:"articlelist",on:{"click":function($event){return _vm.jump(item)}}},[_c('div',{staticClass:"title"},[_vm._v(_vm._s(item.title))]),_c('div',{staticClass:"date"},[_vm._v(_vm._s(_vm._f("format")(item.publishTime)))]),_c('div',{staticClass:"xian"})]):_vm._e()})],2):(_vm.name != '单位简介' && _vm.name != '培训课堂')?_c('div',{staticStyle:{"margin":"15PX"}},[_c('div',{domProps:{"innerHTML":_vm._s(_vm.datedan.content || '')}}),(
          _vm.datedan.contentFileUrl &&
          JSON.parse(_vm.datedan.contentFileUrl).length > 0
        )?_c('div',[_c('ul',_vm._l((JSON.parse(_vm.datedan.contentFileUrl)),function(item,index){return _c('li',{key:index,staticStyle:{"margin-bottom":"10PX"}},[_c('a',{staticStyle:{"color":"#2d52a0"},attrs:{"href":item.url}},[_c('img',{staticStyle:{"width":"20PX"},attrs:{"src":"/images/fujian.png","alt":""}}),_vm._v(" "+_vm._s(item.name)+" ")])])}),0)]):_vm._e()]):_vm._e(),(_vm.name == '登记/查询')?_c('div',{staticClass:"brief"},[_c('eventquery',{attrs:{"id":_vm.id,"pid":_vm.pid}})],1):_vm._e(),(_vm.name == '最高纪录')?_c('div',{staticClass:"brief"},[_c('record',{attrs:{"id":_vm.id,"pid":_vm.pid}})],1):_vm._e(),(_vm.name == '成绩查询')?_c('div',{staticClass:"brief"},[_c('game',{attrs:{"id":_vm.id,"pid":_vm.pid}})],1):_vm._e(),(_vm.name == '赛事报名')?_c('div',{staticClass:"brief"},[_c('eventregistration',{attrs:{"id":_vm.id,"pid":_vm.pid}})],1):_vm._e(),(_vm.name == '竞赛知识' || _vm.name == '裁判员培训')?_c('div',{staticClass:"brief"},[_c('competition',{attrs:{"id":_vm.id,"pid":_vm.pid}})],1):_vm._e(),(_vm.name == '注册/查询')?_c('div',{staticClass:"brief"},[_c('registrationquery',{attrs:{"id":_vm.id,"pid":_vm.pid}})],1):_vm._e(),(_vm.template != 'channel')?_c('div',[(
          _vm.name != '单位简介' &&
          _vm.name != '登记/查询' &&
          _vm.name != '最高纪录' &&
          _vm.name != '成绩查询' &&
          _vm.name != '赛事报名' &&
          _vm.name != '竞赛知识' &&
          _vm.name != '注册/查询' &&
          _vm.name != '裁判员培训' &&
          _vm.name != '培训课堂'
        )?_c('div',{staticClass:"paging"},[_c('pagination',{attrs:{"total":_vm.total,"type":2,"go":10,"pageSize":10,"refreshNum":_vm.refreshNum,"pageNo":_vm.pageNum},on:{"currentchange":_vm.currentchange}})],1):_vm._e()]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }